import * as React from 'react';

import DownloadIcon from '@mui/icons-material/Download';

import {
  Div38,
  StyledButtonDonwload,
  StyledButtonDownloadIcon,
  StyledLabel,
  StyledSheet,
} from './styles';
import AttachmentButton from '../../../../components/AttachmentButton';

interface Props {
  isLoading: boolean;
  hidden: boolean;
  handleUploadAccountFile: (files: FileList) => void;
  handleDownloadSheet: () => void;
}

const SheetAccounts: React.FC<Props> = ({
  isLoading,
  hidden,
  handleUploadAccountFile,
  handleDownloadSheet,
}): React.ReactElement => {
  return (
    <React.Fragment>
      <StyledSheet hidden={hidden}>
        <div>
          <StyledLabel>Download da planilha modelo </StyledLabel>
          <StyledButtonDonwload onClick={handleDownloadSheet}>
            <Div38>Baixar planilha</Div38>
            <StyledButtonDownloadIcon>
              <DownloadIcon htmlColor="#79cc72" />
            </StyledButtonDownloadIcon>
          </StyledButtonDonwload>
        </div>

        <div>
          <StyledLabel>Enviar planlha modelo preenchida *</StyledLabel>
          <AttachmentButton
            label="Anexar arquivo xlsx*"
            disabled={isLoading}
            handleChange={handleUploadAccountFile}
            acceptTypes=".xlsx"
          />
        </div>
      </StyledSheet>
    </React.Fragment>
  );
};

export default SheetAccounts;
