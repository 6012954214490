import { RequestStatusEnum } from '../pages/Home/utils';
import { AccountData } from '../pages/NewRequestV3/StepOne';
import api from '../services/api';

export const uploadFile = async (
  files: FileList,
  type: string,
  token: string
): Promise<any> => {
  const formData = new FormData();

  // eslint-disable-next-line no-restricted-syntax
  for (const file of Array.from(files)) {
    formData.append('files', file);
  }

  const result = await api.post(`/api/upload-file?type=${type}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export interface RequestData {
  payments: string[];
  accounts: string[];
}

export const createRequest = async (
  data: RequestData,
  token: string,
  code?: string
): Promise<any> => {
  const result = await api.post(
    '/api/requests',
    { ...data, code },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export interface RequestDataV2 {
  payments: string[];
  accounts: AccountData[];
}

export const createRequestV2 = async (
  data: RequestDataV2,
  token: string,
  code?: string
): Promise<any> => {
  const result = await api.post(
    '/api/v2/requests',
    { ...data, code },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const preCreateRequest = async (
  data: RequestData,
  token: string,
  code?: string
): Promise<any> => {
  const result = await api.post(
    '/api/pre-requests',
    { ...data, code },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const getAllRequestByUser = async (
  token: string,
  filterStatus: RequestStatusEnum,
  page: number,
  itemsPerPage: number
): Promise<any> => {
  const result = await api.get(
    `/api/requests?status=${filterStatus}&page=${page || '0'}&itemsPerPage=${
      itemsPerPage || ''
    }`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const getAllRequest = async (
  token: string,
  search?: string
): Promise<any> => {
  const result = await api.get(`/api/requests?search=${search || ''}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const updateRequestStatus = async (
  status: string,
  id: string,
  token: string
): Promise<any> => {
  const result = await api.patch(
    `/api/requests/${id}/status`,
    { status },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const deleteRequest = async (
  id: string,
  token: string
): Promise<any> => {
  const result = await api.delete(`/api/requests/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

const downloadFile = (data: any, typeFile: string, filename: string) => {
  const myBlob = new Blob([data], { type: typeFile });

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(myBlob);

  link.setAttribute('download', filename); // Added Line
  link.click();
};

export const downloadRequestAttachmentByUser = async (
  attachmentId: string,
  filename: string,
  token: string
): Promise<any> => {
  const filenameSplited = filename.split('.');
  const typeFile = filenameSplited[filenameSplited.length - 1];

  const result = await api.get(`/api/attachments/${attachmentId}`, {
    responseType: 'arraybuffer', // important
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/*',
    },
  });

  downloadFile(result.data, typeFile, filename);
};

const convertBlobToFile = (blob: Blob): Promise<any> => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.addEventListener(
      'loadend',
      () => {
        resolve(reader.result);
      },
      false
    );

    reader.readAsDataURL(blob);
  });
};

export const previewRequestAttachmentByUser = async (
  attachmentId: string,
  token: string
): Promise<any> => {
  const result = await api.get(`/api/attachments/${attachmentId}`, {
    responseType: 'blob', // important
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/*',
    },
  });

  const { type } = result.data;

  if (type === 'application/pdf') {
    const myBlob = new Blob([result.data], { type: 'pdf' });

    return { urlFile: window.URL.createObjectURL(myBlob), typeFile: 'pdf' };
  }

  const myBlob = result.data;

  const urlFile = await convertBlobToFile(myBlob);

  return { urlFile, typeFile: 'image' };
};

export const downloadRequestAttachmentAdmin = async (
  attachmentId: string,
  filename: string,
  token: string
): Promise<any> => {
  const filenameSplited = filename.split('.');
  const typeFile = filenameSplited[filenameSplited.length - 1];

  const result = await api.get(`/api/attachment/${attachmentId}/download`, {
    responseType: 'arraybuffer', // important
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/*',
    },
  });

  downloadFile(result.data, typeFile, filename);
};

export const getAllUsers = async (
  token: string,
  search?: string,
  filter?: string
): Promise<any> => {
  const result = await api.get(
    `/api/users?search=${search || ''}&filter=${filter || ''}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result?.data;
};

export const getUserById = async (token: string, id: string): Promise<any> => {
  const result = await api.get(`/api/users/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

interface UserData {
  username: string;
  code: string;
  name: string;
  role: string;
  email: string;
}

export const createUser = async (
  data: UserData,
  token: string
): Promise<any> => {
  const result = await api.post('/api/users', data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

interface UserEditData {
  username: string;
  status: string;
  name: string;
  role: string;
  email: string;
}

export const editUser = async (
  userId: string,
  data: UserEditData,
  token: string
): Promise<any> => {
  const result = await api.patch(`/api/users/${userId}`, data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return result?.data;
};

export const exportRequest = async (
  startDate: string,
  endDate: string,
  status: string,
  token: string
): Promise<any> => {
  const result = await api.post(
    `/api/export-requests`,
    {
      startDate,
      endDate,
      status,
    },
    {
      responseType: 'arraybuffer', // important
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/*',
      },
    }
  );

  downloadFile(result.data, 'xlsx', `report-${new Date().toISOString()}.xlsx`);
};

export default {
  uploadFile,
  createRequest,
  getAllRequestByUser,
  getAllRequest,
  downloadRequestAttachmentByUser,
  downloadRequestAttachmentAdmin,
  getAllUsers,
  getUserById,
  createUser,
  editUser,
};
