import styled from 'styled-components';

export const Div = styled.div`
  background-color: #fff;
  min-height: calc(100vh - 55px);
  padding: 32px 16px;
  font-family: 'Inter', sans-serif;

  @media (max-width: 991px) {
    padding: 10px 20px;
  }
`;

export const Div2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 40px;

  @media (max-width: 991px) {
    .table-custom .MuiPaper-root {
      box-shadow: none;
    }
  }
`;

export const StyledRequestsContainer = styled.div`
  margin-top: 50px;
  width: 100%;
  border: 1px solid #e9ebec;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.19);

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

export const StyledRequestsContainerTable = styled.div`
  margin-top: 50px;
  width: 100%;

  @media (max-width: 991px) {
    margin-top: 40px;

    table {
      border: 0;
    }

    table table {
      border: 3px solid #e9ebec;
    }

    table table td {
      text-align: left;
    }

    table table td:before {
      margin-right: 8px;
    }

    table table td:last-child {
      padding-top: 16px;
    }

    table caption {
      font-size: 1.3em;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      display: block;

      &:nth-child(odd) {
        border: 1px solid #e9ebec;
      }

      &:nth-child(even) {
        padding: 20px 0;
      }
    }

    table td {
      display: block;
      font-size: 0.9em;
      text-align: right;
    }

    table td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    table td:last-child {
      border-bottom: 0;
    }
  }
`;

export const StyledLoading = styled.div`
  margin-top: 50px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 200px;
  padding: 30px;
  box-sizing: border-box;
  gap: 40px;
  color: #3377cc;
  font-weight: 500;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

export const StyleContentTitleContainer = styled.div`
  padding: 14px;
  border-bottom: 2px solid #e9ebec;
  font-family: 'Inter', sans-serif;
`;

export const StyledContentContainer = styled.div`
  padding: 52px 14px 16px;
  @media (max-width: 991px) {
    padding: 40px 8px 12px;
  }
`;

export const StyledAreaContainer = styled.div`
  border: 2px dashed #e9ebec;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre;
  flex-direction: column;
  width: 100%;
  padding: 26px 0px;

  &:hover {
    cursor: pointer;
    border: 2px solid #79cc72;
  }
`;

export const StyledPlaneContainer = styled('div')({
  '> svg': {
    width: '127px',
  },
});

export const StyledSpanContent = styled.span`
  width: 100%;
  text-align: center;
  font-family: 'Inter', sans-serif;
`;

export const StyledContentRequestItem = styled.span`
  color: #878a99;
`;

export const StyledHeaderTitlePage = styled.div`
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`;

export const StyledContentButtonsHeader = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledContentFilterStatus = styled.div`
  margin-top: 8px;
`;

export const StyledContentPaymentField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyleLinkOldRequestsContainer = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
});

export const StyleLinkOldRequests = styled('div')({
  padding: '4px 0px',
  cursor: 'pointer',

  '&:hover': {
    textDecoration: 'underline',
  },
});
