import * as React from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { CircularProgress, Divider, Typography } from '@mui/material';

import {
  Div25,
  Div28,
  Div28Content,
  Div37,
  Div38,
  StyledButtonBack,
  StyledContentButtons,
} from './styles';

import { formatNumber } from '../../../utils/number';
import { formatMoney } from '../../../utils/money';
import HeaderRequest from '../../../components/HeaderRequest';

interface Props {
  resumeData: any;
  isLoading: boolean;
  handleSubmitResume: () => void;
  goBack: () => void;
}

const StepTwoRequest: React.FC<Props> = ({
  resumeData,
  isLoading,
  handleSubmitResume,
  goBack,
}): React.ReactElement => {
  return (
    <React.Fragment>
      <HeaderRequest
        title="2. Resumo da solicitação"
        subtitle="Confira as informações da sua solicitação"
      />

      <Div25>
        <Div28Content>
          <Div28>
            <div>Contas distintas:</div> {resumeData?.totalCpfs} contas
          </Div28>
          <Div28>
            <div>Total de pontos do banco:</div>{' '}
            {formatNumber(resumeData?.totalMiles)} pontos
          </Div28>
        </Div28Content>
        <Div28Content>
          <Div28>
            <div>Média de pontos por conta:</div>{' '}
            {formatNumber(resumeData?.averageMilesPerCpf)} pontos
          </Div28>
          <Div28>
            <div>Pontuação máxima de uma conta:</div>{' '}
            {formatNumber(resumeData?.biggestValue)} pontos
          </Div28>
        </Div28Content>
        <Div28Content>
          <Div28>
            <div>Valor total da solicitação:</div>{' '}
            {formatMoney(resumeData?.totalValue)}
          </Div28>
          <Div28>
            <div>Valor médio por 1.000 pontos:</div>{' '}
            {formatMoney(
              (resumeData?.totalValue / resumeData?.totalMiles) * 1000
            )}
          </Div28>
        </Div28Content>
      </Div25>
      <div style={{ padding: '24px 0px' }}>
        <Divider />
      </div>
      <div
        style={{
          padding: '0px 16px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography component="h2" style={{ textDecoration: 'underline' }}>
            Atenção
          </Typography>
        </div>
        <ul style={{ color: '#878A99', fontFamily: 'Rubik', paddingTop: 10 }}>
          <li>
            <Typography component="p" fontSize={13} fontWeight={300}>
              Os pontos das solicitações feitas até as 12pm serão creditados
              entre 16h e 23h do mesmo dia.
            </Typography>
          </li>
          <li>
            <Typography component="p" fontSize={13} fontWeight={400}>
              As solicitações feitas após 12pm terão os pontos creditados entre
              16h e 23h do dia seguinte.
            </Typography>
          </li>
          <li>
            <Typography component="p" fontSize={13} fontWeight={300}>
              O crédito das milhas no programa aéreo é o consumo do produto, não
              possibilitando estorno ou reembolso.
            </Typography>
          </li>
          <li>
            <Typography component="p" fontSize={13} fontWeight={400}>
              Não existe garantia de crédito imediato do bônus, favor verificar
              o prazo do regulamento da campanha.
            </Typography>
          </li>
          <li>
            <Typography component="p" fontSize={13} fontWeight={300}>
              O sistema divide automaticamente o crédito dos pontos de 100.000
              em 100.000 milhas.
            </Typography>
          </li>
          <li>
            <Typography component="p" fontSize={13} fontWeight={400}>
              O cadastro na campanha e análise do percentual de bônus elegível é
              de sua total responsabilidade.
            </Typography>
          </li>
        </ul>
      </div>
      {/* <GreenSquare>
        Solicitações até meio-dia serão creditadas entre 16h e 23h do mesmo dia.
      </GreenSquare> */}
      <StyledContentButtons>
        <StyledButtonBack onClick={() => goBack()}>
          <Div38>Voltar</Div38>
        </StyledButtonBack>
        <Div37 onClick={() => handleSubmitResume()}>
          <Div38>Próximo</Div38>
          {isLoading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <ArrowForwardIcon />
          )}
        </Div37>
      </StyledContentButtons>
    </React.Fragment>
  );
};

export default StepTwoRequest;
