import * as React from 'react';
import RemoveIcon from '@mui/icons-material/RemoveCircle';

import {
  StyledBox,
  StyledIcon,
  StyledItem,
  StyledLabel,
  StyledRoot,
  StyledText,
} from './styles';
import { formatNumber } from '../../../../utils/number';

interface Props {
  isLoading: boolean;
  data: any[];
  removeData: (i: number) => void;
}

const ListAccounts: React.FC<Props> = ({
  data,
  removeData,
}): React.ReactElement => {
  return (
    <React.Fragment>
      {!!data.length && (
        <StyledRoot>
          <StyledLabel>Lista de titulares</StyledLabel>

          <StyledBox>
            {data.map((item, index) => (
              <StyledItem key={index}>
                <StyledText>
                  {item.name} - {formatNumber(item.miles)} pontos{' '}
                </StyledText>
                <StyledIcon onClick={() => removeData(index)}>
                  <RemoveIcon htmlColor="#f00" fontSize="inherit" />
                </StyledIcon>
              </StyledItem>
            ))}
          </StyledBox>
        </StyledRoot>
      )}
    </React.Fragment>
  );
};

export default ListAccounts;
