import styled from 'styled-components';

export const Div18 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  border-bottom: 1px solid #79cc7233;
  margin-bottom: 10px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const Div19 = styled.div`
  color: #79cc72;
  font: 500 16px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const Div20 = styled.div`
  color: #79cc72;
  font: 275 12px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
