/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
export const isCnpjValid = (cnpj: string): boolean => {
  cnpj = cnpj.replace(/[^\d]/g, '');
  if (cnpj.length !== 14) {
    return false;
  }
  let soma = 0;
  let peso = 5;
  for (let i = 0; i < 12; i++) {
    soma += parseInt(cnpj.charAt(i), 10) * peso;
    peso = peso === 2 ? 9 : peso - 1;
  }
  let digitoVerificador1 = 11 - (soma % 11);
  if (digitoVerificador1 > 9) {
    digitoVerificador1 = 0;
  }
  if (parseInt(cnpj.charAt(12), 10) !== digitoVerificador1) {
    return false;
  }
  soma = 0;
  peso = 6;
  for (let i = 0; i < 13; i++) {
    soma += parseInt(cnpj.charAt(i), 10) * peso;
    peso = peso === 2 ? 9 : peso - 1;
  }
  let digitoVerificador2 = 11 - (soma % 11);
  if (digitoVerificador2 > 9) {
    digitoVerificador2 = 0;
  }
  if (parseInt(cnpj.charAt(13), 10) !== digitoVerificador2) {
    return false;
  }
  return true;
};
