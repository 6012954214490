/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Lottie from 'react-lottie';
import EditIcon from '@mui/icons-material/Edit';
import AddLinkIcon from '@mui/icons-material/AddLink';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import {
  Div2,
  Div,
  StyledRequestsContainer,
  StyleContentTitleContainer,
  StyledAreaContainer,
  StyledPlaneContainer,
  StyledSpanContent,
  StyledContentContainer,
  StyledRequestsContainerTable,
  StyledLoading,
  StyledContentSearch,
} from './styles';

import Plane from '../../images/Plane';
import {
  createUser,
  editUser,
  getAllUsers,
  getUserById,
} from '../../api/request';
import useAuthenticate from '../../recoil/hooks/authenticate';
import { formatShortDate } from '../../utils/date';
import { maskCpf } from '../../utils/mask';
import NewLoggedHeader from '../../components/NewLoggedHeader';
import animationData from './loading.json';

import SearchInput from '../../components/SearchInput';
import { formatUserRole, formatUserStatus } from './utils';
import ModalEditUser from './ModalEditUser';
import { ButtonWithIcon } from '../../components/Button';
import ModalCreateUser from './ModalCreateUser';
import UserTableCollapse from './UserTableCollapse';
import ModalAddCode from './ModalAddCode';
import { createCode } from '../../api/usercodes';
import SimpleSelect from '../Login/Select';
import { StyledContentFilterStatus } from '../Home/styles';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Users = (): React.ReactElement => {
  const navigate = useNavigate();

  const [filter, setFilter] = React.useState('partner');
  const [search, setSearch] = React.useState('');

  const [isLoading, setIsLoading] = React.useState(true);

  const [isEditing, setIsEditing] = React.useState(false);
  const [isCreating, setIsCreating] = React.useState(false);

  const [isCreatingCode, setCreatingCode] = React.useState(false);

  const { authenticate } = useAuthenticate();

  const [data, setData] = React.useState([]);

  const [userSelected, setUserSelected] = React.useState<any>();

  const getData = React.useCallback(
    async (search?: string, filter?: string) => {
      try {
        setIsLoading(true);
        const data = await getAllUsers(authenticate.token, search, filter);

        setData(data.users);
      } catch (error) {
        toast(
          'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
          {
            type: 'error',
          }
        );
      } finally {
        setIsLoading(false);
      }
    },
    [authenticate.token]
  );

  const getUser = React.useCallback(
    async (id: string): Promise<any> => {
      try {
        const data = await getUserById(authenticate.token, id);

        return data;
      } catch (error: any) {
        toast(
          'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
          {
            type: 'error',
          }
        );
        return null;
      }
    },
    [authenticate.token]
  );

  React.useEffect(() => {
    if (authenticate.isAuthenticate) {
      getData('', filter);
    }
  }, [authenticate.isAuthenticate, getData, filter]);

  const handleSearch = (search: string): void => {
    setSearch(search);
    getData(search, filter);
  };

  const prepareData = (data: any) => {
    return data.map((item: any) => ({
      id: item._id,
      shortId: item._id.substring(17, item._id.length),
      name: item.name,
      cpf: maskCpf(item.cpf),
      role: formatUserRole(item.role),
      status: formatUserStatus(item.status),
      lastAccess: formatShortDate(item.lastAccess),
      email: item.email,
    }));
  };

  const handleSelectUserToEdit = async (id: string): Promise<void> => {
    const data = await getUser(id);

    setUserSelected(data.user);

    setIsEditing(true);
  };

  const handleSelectUserToAddCode = async (id: string): Promise<void> => {
    const data = await getUser(id);

    setUserSelected(data.user);

    setCreatingCode(true);
  };

  const handleSubmitCreateuser = async (values: any): Promise<void> => {
    try {
      await createUser(
        {
          code: values.code,
          name: values.name,
          role: values.role,
          username: values.cpf,
          email: values.email,
        },
        authenticate.token
      );
      setIsCreating(false);

      getData(search, filter);
    } catch (error: any) {
      const message =
        error.response?.status === 409
          ? 'CPF ou E-mail já cadastrado!'
          : 'Não foi possível realizar sua solicitação. Por favor, tente novamente!';

      toast(message, {
        type: 'error',
      });
    }
  };

  const handleSubmitEdituser = async (values: any): Promise<void> => {
    try {
      if (userSelected) {
        await editUser(
          userSelected._id,
          {
            status: values.status,
            name: values.name,
            role: values.role,
            username: values.cpf,
            email: values.email,
          },
          authenticate.token
        );
      }
      setIsEditing(false);

      getData(search, filter);
    } catch (error: any) {
      const message =
        error.response?.status === 409
          ? 'CPF já cadastrado!'
          : 'Não foi possível realizar sua solicitação. Por favor, tente novamente!';

      toast(message, {
        type: 'error',
      });
    }
  };

  const handleSubmitCreateCode = async (values: any): Promise<void> => {
    try {
      if (userSelected) {
        await createCode(
          userSelected._id,
          {
            name: values.name,
            code: values.code,
            url: values.url,
            partnerValue: Number(values.partnerValue) / 100,
            useValueClientSheet: values.useValueClientSheet,
            valueMiles: Number(values.valueMiles) / 100,
          },
          authenticate.token
        );
      }
      setCreatingCode(false);

      getData(search, filter);
    } catch (error: any) {
      const message =
        error.response?.status === 409
          ? 'Código já está sendo utilizado!'
          : 'Não foi possível realizar sua solicitação. Por favor, tente novamente!';

      toast(message, {
        type: 'error',
      });
    }
  };

  return (
    <Div>
      <Div2>
        <NewLoggedHeader />

        <StyledContentSearch>
          <StyledContentFilterStatus>
            <SimpleSelect
              name="status"
              value={filter}
              onChange={(event) => {
                setFilter(event.target.value);
              }}
              options={[
                {
                  value: 'partner',
                  label: 'Parceiro / Admin',
                },
                {
                  value: 'user',
                  label: 'Cliente',
                },
                {
                  value: 'all',
                  label: 'Todos',
                },
              ]}
            />
          </StyledContentFilterStatus>

          <ButtonWithIcon
            label="Adicionar usuário"
            icon={<PersonAddAltIcon />}
            onClick={() => setIsCreating(true)}
          />
          <SearchInput handleSubmit={handleSearch} />
        </StyledContentSearch>

        {isLoading && (
          <StyledLoading>
            <Lottie options={defaultOptions} height={150} width={150} />
            <div>Carregando</div>
          </StyledLoading>
        )}

        {!isLoading && !data.length && (
          <StyledRequestsContainer>
            <StyleContentTitleContainer>
              <span>Você ainda não tem solicitações :(</span>
            </StyleContentTitleContainer>
            <StyledContentContainer>
              <StyledAreaContainer
                onClick={() => {
                  navigate('/new-request');
                }}
              >
                <StyledPlaneContainer>
                  <Plane />
                </StyledPlaneContainer>
                <StyledSpanContent>
                  <strong>
                    <u>Clique aqui</u>
                  </strong>{' '}
                  para fazer sua primeira solicitação.
                </StyledSpanContent>
              </StyledAreaContainer>
            </StyledContentContainer>
          </StyledRequestsContainer>
        )}

        {!isLoading && !!data.length && (
          <StyledRequestsContainerTable>
            <UserTableCollapse
              data={prepareData(data)}
              handleSelectUserToAddCode={(id) => handleSelectUserToAddCode(id)}
              rows={[
                'shortId',
                'name',
                'email',
                'cpf',
                'role',
                'status',
                'lastAccess',
              ]}
              head={[
                'ID',
                'Nome',
                'E-mail',
                'CPF',
                'Permissão',
                'Status',
                'Último acesso',
              ]}
              handleDownload={() => null}
              options={[
                {
                  id: 'edit',
                  label: 'Editar',
                  icon: <EditIcon fontSize="small" />,
                  onClick: (id) => handleSelectUserToEdit(id),
                },
                {
                  id: 'add-code',
                  label: 'Adicionar código',
                  icon: <AddLinkIcon fontSize="small" />,
                  onClick: (id) => handleSelectUserToAddCode(id),
                },
              ]}
            />
          </StyledRequestsContainerTable>
        )}
      </Div2>

      <ModalEditUser
        title={'Editar Usuário'}
        open={isEditing}
        handleSubmit={handleSubmitEdituser}
        isLoading={false}
        defaultValues={userSelected}
        handleClose={() => setIsEditing(false)}
      />

      <ModalAddCode
        title={'Adicionar código'}
        open={isCreatingCode}
        handleSubmit={handleSubmitCreateCode}
        isLoading={false}
        handleClose={() => setCreatingCode(false)}
      />

      <ModalCreateUser
        title={'Adicionar Usuário'}
        open={isCreating}
        handleSubmit={handleSubmitCreateuser}
        isLoading={false}
        handleClose={() => setIsCreating(false)}
      />
    </Div>
  );
};

export default Users;
