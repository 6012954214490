import React, { FC, ReactElement } from 'react';
import {
  StyleContentChangeStatus,
  StyleContentChangeStatusContent,
  StyledCardStatus,
} from './styles';

import {
  requestStatusColor,
  RequestStatusEnum,
  requestStatusLabel,
} from '../../pages/HomeV2/utils';
import { Button } from '../Button';

interface Props {
  item: any;
  handleSubmit: (id: string, values: any) => void;
}

const CardStatus: FC<Props> = ({ item, handleSubmit }): ReactElement => {
  const getOptions = () => {
    const options = [
      {
        value: RequestStatusEnum.pending,
        label: requestStatusLabel(RequestStatusEnum.pending),
      },
      {
        value: RequestStatusEnum.analysing,
        label: requestStatusLabel(RequestStatusEnum.analysing),
      },
      {
        value: RequestStatusEnum.confirmed,
        label: requestStatusLabel(RequestStatusEnum.confirmed),
      },
      {
        value: RequestStatusEnum.waitingCredit,
        label: requestStatusLabel(RequestStatusEnum.waitingCredit),
      },
      {
        value: RequestStatusEnum.completed,
        label: requestStatusLabel(RequestStatusEnum.completed),
      },
    ];

    if (item?.status === RequestStatusEnum.pending) {
      return [options[1]];
    }

    if (item?.status === RequestStatusEnum.analysing) {
      return [options[0], options[2]];
    }

    if (item?.status === RequestStatusEnum.confirmed) {
      return [options[1], options[3]];
    }

    if (item?.status === RequestStatusEnum.waitingCredit) {
      return [options[2], options[4]];
    }

    return [options[3]];
  };

  return (
    <StyledCardStatus>
      <StyleContentChangeStatus>
        <StyleContentChangeStatusContent>
          A solicitação está com o status{' '}
          <span
            style={{
              fontWeight: 'bold',
              color: requestStatusColor(item.status),
            }}
          >
            {requestStatusLabel(item.status)}
          </span>
        </StyleContentChangeStatusContent>
        <div style={{ display: 'flex', gap: 12 }}>
          {getOptions()?.map((option, index) => (
            <Button
              key={index}
              onClick={() => handleSubmit(item.id, { status: option.value })}
              style={{
                fontWeight: 'bold',
                marginTop: 0,
                color: requestStatusColor(option.value),
                borderColor: requestStatusColor(option.value),
              }}
            >
              {option.label}
            </Button>
          ))}
        </div>
      </StyleContentChangeStatus>
    </StyledCardStatus>
  );
};

export default CardStatus;
