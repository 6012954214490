import styled from 'styled-components';

export const StyledCardComments = styled('div')(({ onClick }) => ({
  boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.25)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  padding: '16px',
  position: 'relative',
  cursor: onClick ? 'pointer' : undefined,
  transition: '0.4s',
  fontFamily: 'Inter',
  color: '#79cc72',
  gap: 16,
  display: 'flex',
  flexDirection: 'column',

  '& > div': {
    paddingBottom: 16,
    borderBottom: '1px solid #ccc',
  },

  '& > div:last-child': {
    paddingBottom: 0,
    borderBottom: 'none',
  },

  '&:hover': {
    transform: onClick ? 'scale(1.01)' : '',
  },

  maxHeight: 500,
  overflowY: 'auto',
}));

export const StyledContentCardInfo = styled('div')({
  color: '#878A99',
  width: '100%',
  fontWeight: 300,
  display: 'flex',
  flexDirection: 'column',
  gap: 6,
  '> div': {
    display: 'flex',
    gap: 4,
  },
  '> div > div': {
    fontWeight: 400,
  },

  '@media (max-width: 600px)': {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

export const StyledDivider = styled('div')({
  display: 'none !important',
  '@media (min-width: 600px)': {
    display: 'block !important',
  },
});

export const StyledAction = styled('div')({
  fontSize: 14,
  fontWeight: 500,
  cursor: 'pointer',
  padding: '12px 0px',
  boxSizing: 'border-box',

  '&:hover': {
    textDecoration: 'underline',
  },
});
