/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */

import { maskCnpj } from './mask';

export const isCpfValid = (cpf: string): boolean => {
  cpf = cpf.replace(/[^\d]/g, '');
  if (cpf.length !== 11) {
    return false;
  }
  if (/^(\d)\1{10}$/.test(cpf)) {
    return false;
  }
  let soma = 0;
  for (let i = 0; i < 9; i++) {
    soma += parseInt(cpf.charAt(i), 10) * (10 - i);
  }
  let resto = soma % 11;
  const digitoVerificador1 = resto < 2 ? 0 : 11 - resto;
  if (parseInt(cpf.charAt(9), 10) !== digitoVerificador1) {
    return false;
  }
  soma = 0;
  for (let i = 0; i < 10; i++) {
    soma += parseInt(cpf.charAt(i), 10) * (11 - i);
  }
  resto = soma % 11;
  const digitoVerificador2 = resto < 2 ? 0 : 11 - resto;
  if (parseInt(cpf.charAt(10), 10) !== digitoVerificador2) {
    return false;
  }
  return true;
};

export const cpfMask = (value: string): string => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const removeCpfMask = (value: string): string => {
  return value.replaceAll('.', '').replace('-', '');
};

export const maskCnpjOrCpf = (v: string): string => {
  if (!v) {
    return '-';
  }

  v = v.replace(/\D/g, '');

  if (v.length <= 11) {
    return cpfMask(v);
  }

  return maskCnpj(v);
};

export const removeMask = (value: string): string => {
  if (!value) {
    return '';
  }
  return value.toString().replace(/\D/g, '');
};
