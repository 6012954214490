import styled from 'styled-components';

export const StyledSheet = styled('div')(({ hidden }) => ({
  flex: 1,
  width: '100%',
  display: hidden ? 'none' : 'flex',
  flexDirection: 'column',
  gap: 32,
  paddingTop: 24,
}));

export const StyledLabel = styled.div`
  font-family: Rubik, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  color: #79cc72;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 14px;
`;

export const StyledButtonDonwload = styled.div`
  width: 240px;
  max-width: 100%;
  border-radius: 4px;
  background-color: #79cc72;
  display: flex;
  padding-left: 13px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  margin: auto 0;
  height: 40px;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    background-color: #69bc62;
  }
`;

export const StyledButtonDownloadIcon = styled.div`
  background-color: #d7ffd3;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
`;

export const Div38 = styled.div`
  font-family: Poppins, sans-serif;
  margin: auto 0;
`;
