import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Document, Page } from 'react-pdf';

import { CircularProgress, IconButton } from '@mui/material';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import useAuthenticate from '../../recoil/hooks/authenticate';
import SelectExtract from './SelectExtract';
import {
  StyleBox,
  StyleContentImage,
  StyleContentImageItem,
  StyledModal,
  StyledModalButton,
  StyleIconButton,
  StyleImage,
} from './styles';

interface Props {
  open: boolean;
  url: string;
  fileId: string;
  typeFile: string;
  payments: any[];
  isLoading: boolean;
  extracts?: any[];
  handleClose: () => void;
  handleSelectExtract?: (id: string, isNext?: boolean) => void;
  handlePreviewFile?: (
    fileId: string,
    fileName: string,
    payments: any[]
  ) => void;
}

export default function ModalPreviewImage({
  open,
  url,
  typeFile,
  fileId,
  payments,
  isLoading,
  extracts,
  handleSelectExtract,
  handleClose,
  handlePreviewFile,
}: Props): React.ReactElement {
  const { authenticate } = useAuthenticate();

  const { width } = useWindowDimensions();

  const [prev, next] = React.useMemo(() => {
    const index = payments.findIndex((item) => item.id === fileId);

    const prev = index > 0 ? payments[index - 1] : null;

    const next = index < payments.length ? payments[index + 1] : null;

    return [prev, next];
  }, [fileId, payments]);

  const showExtract =
    extracts && handleSelectExtract && authenticate?.user?.role === 'admin';

  const selectExtract = async (id: string): Promise<void> => {
    if (handleSelectExtract) {
      await handleSelectExtract(id, Boolean(next));
    }

    if (next && handlePreviewFile) {
      handlePreviewFile(next.id, next.name, payments);
    }
  };

  return (
    <StyledModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      keepMounted={false}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <React.Fragment>
        {handlePreviewFile && (
          <StyledModalButton className="button-left">
            <IconButton
              style={{ background: '#fff' }}
              disabled={!prev || isLoading}
              onClick={() => handlePreviewFile(prev.id, prev.name, payments)}
            >
              <ArrowBackIcon fontSize="large" />
            </IconButton>
          </StyledModalButton>
        )}
        <Fade in={open}>
          <StyleBox
            style={{
              minWidth: showExtract ? 1200 : 600,
            }}
          >
            <StyleIconButton onClick={handleClose}>
              <CloseIcon fontSize="small" color="error" />
            </StyleIconButton>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Pagamento - {fileId?.substring(17, fileId.length)}
            </Typography>

            {isLoading && (
              <div
                style={{
                  width: 350,
                  height: 450,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </div>
            )}

            {!isLoading && (
              <StyleContentImage>
                <StyleContentImageItem>
                  {typeFile === 'pdf' ? (
                    <Document file={url}>
                      <Page
                        pageNumber={1}
                        renderTextLayer={false}
                        renderAnnotationLayer={false}
                        width={width > 1650 ? 780 : 580}
                      />
                    </Document>
                  ) : (
                    <StyleImage src={url} />
                  )}
                </StyleContentImageItem>
                {showExtract && (
                  <SelectExtract
                    fileId={fileId}
                    extracts={extracts}
                    handleSelectExtract={selectExtract}
                  />
                )}
              </StyleContentImage>
            )}
          </StyleBox>
        </Fade>
        {handlePreviewFile && (
          <StyledModalButton className="button-right">
            <IconButton
              style={{ background: '#fff' }}
              disabled={!next || isLoading}
              onClick={() => handlePreviewFile(next.id, next.name, payments)}
            >
              <ArrowForwardIcon fontSize="large" />
            </IconButton>
          </StyledModalButton>
        )}
      </React.Fragment>
    </StyledModal>
  );
}
