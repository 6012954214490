import * as React from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClearIcon from '@mui/icons-material/Clear';

import { CircularProgress, IconButton } from '@mui/material';

import { toast } from 'react-toastify';
import {
  Div18,
  Div20,
  Div25,
  Div28,
  Div37,
  Div38,
  StyledButtonBack,
  StyledContentFiles,
  StyledContentFilesItem,
  GreenSquare,
  Div32,
} from './styles';

import { formatMoney } from '../../../utils/money';
import AttachmentButton from '../../../components/AttachmentButton';
import useAuthenticate from '../../../recoil/hooks/authenticate';
import { uploadFile } from '../../../api/request';
import HeaderRequest from '../../../components/HeaderRequest';

interface AttachmentFile {
  id: string;
  name: string;
}

interface Props {
  resumeData: any;
  loading: boolean;
  goBack: () => void;
  paymentFiles: AttachmentFile[];
  handleSubmitPayments: (items: AttachmentFile[]) => void;
  setPaymentsFiles: (items: AttachmentFile[]) => void;
}

const StepThreeRequest: React.FC<Props> = ({
  resumeData,
  loading,
  goBack,
  paymentFiles,
  setPaymentsFiles,
  handleSubmitPayments,
}): React.ReactElement => {
  const { authenticate } = useAuthenticate();
  const [isLoading, setIsLoading] = React.useState(false);

  const removePaymentItem = (id: string): void => {
    setPaymentsFiles(paymentFiles.filter((item) => item.id !== id));
  };

  const handleUploadPaymentFile = async (files: FileList): Promise<void> => {
    try {
      setIsLoading(true);

      const result = await uploadFile(files, 'payment', authenticate.token);

      setPaymentsFiles([...paymentFiles, ...result.data]);
    } catch (error) {
      toast(
        'Não foi possível fazer o upload do arquivo. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (): void => {
    if (isLoading || loading) {
      return;
    }
    if (!paymentFiles.length) {
      toast('Por favor, anexe os comprovantes de pagamento.', {
        type: 'error',
      });
    } else {
      handleSubmitPayments(paymentFiles);
    }
  };

  return (
    <React.Fragment>
      <Div18>
        <HeaderRequest
          title="3. Pagamento"
          subtitle="Efetue o pagamento da sua solicitação"
        />

        <Div28>
          <div>Valor a ser pago:</div> {formatMoney(resumeData?.totalValue)}
        </Div28>
        <Div20>
          Anexe um ou mais comprovantes de pagamento de até 10Mb por arquivo.
        </Div20>
      </Div18>
      <Div25>
        <AttachmentButton
          label="Anexar comprovante*"
          disabled={isLoading || loading}
          handleChange={handleUploadPaymentFile}
          acceptTypes="image/*,.pdf"
          multiple
        />
        {paymentFiles?.length ? (
          <Div28>
            {paymentFiles?.length > 1
              ? `${paymentFiles.length} arquivos adicionados`
              : '1 arquivo adicionado'}
          </Div28>
        ) : (
          <Div28>Nenhum arquivo adicionado</Div28>
        )}
        <StyledContentFiles>
          {paymentFiles.map((file) => (
            <StyledContentFilesItem key={file.id}>
              <div>{file.name}</div>
              <IconButton onClick={() => removePaymentItem(file.id)}>
                <ClearIcon fontSize="small" color="error" />
              </IconButton>
            </StyledContentFilesItem>
          ))}
        </StyledContentFiles>
      </Div25>
      <GreenSquare>
        RP Travel & Tech • Chave Pix: 54.264.159/0001-42 (cnpj)
      </GreenSquare>
      <Div32>
        <StyledButtonBack onClick={() => goBack()}>
          <Div38>Voltar</Div38>
        </StyledButtonBack>
        <Div37 onClick={() => handleSubmit()}>
          <Div38>Finalizar</Div38>
          {isLoading || loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <ArrowForwardIcon />
          )}
        </Div37>
      </Div32>
    </React.Fragment>
  );
};

export default StepThreeRequest;
