import styled from 'styled-components';

export const Div = styled.div`
  background-color: #fff;
  min-height: calc(100vh - 55px);
  padding: 32px 0;
  font-family: 'Inter', sans-serif;

  @media (max-width: 991px) {
    padding: 10px 20px;
  }
`;

export const Div2 = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  flex-direction: column;
  margin: 0 auto 40px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const Div8 = styled.div`
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: #79cc72;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  color: #fff;
  padding: 16px 32px 32px;
  width: 100%;
  max-width: 1000px;
  margin: 7px auto 0;
  flex-direction: column;

  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin-top: 40px;
    padding: 16px 24px 32px;
  }
`;

export const Div9 = styled.div`
  flex-grow: 1;
  flex-basis: auto;
  font: bold 20px Poppins, sans-serif;
`;

export const Div10 = styled.div`
  flex-grow: 1;
  flex-basis: auto;
  font: bold 14px Poppins, sans-serif;
  padding-top: 6px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const Div11 = styled.div`
  align-self: center;
  z-index: 10;
  display: flex;
  margin-top: -16px;
  width: 924px;
  max-width: 100%;
  flex-direction: column;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

export const Div12 = styled.div`
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(56, 65, 74, 0.15);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 20px 30px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
  }
`;

export const Div13 = styled.div`
  color: #495057;
  font: 500 16px Poppins, sans-serif;
  width: 100%;
  padding: 16px 0px;

  display: flex;
  align-items: center;
`;

export const StyledStepsContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 18px;
`;

export const StyledStepItemContainer = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  gap: 17px;

  @media (max-width: 991px) {
    width: 100%;
    display: none;
  }

  @media (max-width: 830px) {
  }
`;

export const StyledStepItem = styled('div')<{ selected?: string }>(
  ({ selected }) => {
    const isSelected = selected === 'true';

    return {
      width: '241px',
      height: '41px',
      fontSize: '12px',
      padding: '14px 18px',
      display: 'flex',
      alignItems: 'center',
      border: `1px solid ${isSelected ? '#79CC72' : '#E9EBEC'}`,
      borderRadius: '5px',
      color: isSelected ? '#212529' : '#6D7080',

      '> strong': {
        paddingRight: '4px',
      },
    };
  }
);

export const StyledStepContentContainer = styled.div`
  width: calc(100% - 250px);
  padding: 0px 10px;
  box-sizing: border-box;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const Div14 = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  margin: 16px 0 0 16px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

export const Div15 = styled.div`
  border-radius: 4px;
  border: 1px solid #79cc72;
  display: flex;
  gap: 8px;
  font-size: 12px;
  color: #212529;
  padding: 13px 17px;
`;

export const Img3 = styled.img`
  aspect-ratio: 0.93;
  object-fit: auto;
  object-position: center;
  padding-right: 8px;
`;

export const Div16 = styled.div`
  font-family: Poppins, sans-serif;
  font-weight: 500;
  margin: auto 0;
`;

export const Div17 = styled.div`
  font-family: Poppins, sans-serif;
  font-weight: 400;
  flex-grow: 1;
`;

export const Div21 = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 9px 0 0 15px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

export const Div22 = styled.div`
  border-radius: 4px;
  border: 1px solid #e9ebec;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  color: #6d7080;
  margin: auto 0;
  padding: 14px 17px;
`;

export const Img4 = styled.img`
  aspect-ratio: 0.93;
  object-fit: auto;
  object-position: center;
  padding-right: 8px;
  align-self: stretch;
`;

export const Div23 = styled.div`
  font-family: Poppins, sans-serif;
  font-weight: 500;
  letter-spacing: 0.2px;
  align-self: stretch;
  margin: auto 0;
`;

export const Div24 = styled.div`
  font-family: Poppins, sans-serif;
  font-weight: 400;
  align-self: stretch;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
`;

export const Div26 = styled.div`
  border-radius: 6px;
  border: 2px dashed #e9ebec;
  display: flex;
  justify-content: space-between;
  gap: 6px;
  font-size: 12px;
  color: #79cc72;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  padding: 10px 56px;
  width: 100%;
  max-width: 300px;
  align-self: center;
  margin: 10px 0px;

  @media (max-width: 991px) {
    white-space: initial;
  }

  &:hover {
    cursor: pointer;
    border: 2px solid #79cc72;
  }
`;

export const Img5 = styled.img`
  aspect-ratio: 1.11;
  object-fit: auto;
  object-position: center;
  width: 20px;
`;

export const Div27 = styled.div`
  font-family: Poppins, sans-serif;
  align-self: start;
  margin-top: 5px;
  flex-grow: 1;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export const Div29 = styled.div`
  display: flex;
  width: 832px;
  max-width: 100%;
  padding-right: 18px;
  justify-content: space-between;
  gap: 20px;
  margin: 10px 0 72px 15px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
`;

export const Div30 = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

export const Div31 = styled.div`
  border-radius: 4px;
  border: 1px solid #e9ebec;
  display: flex;
  gap: 6px;
  font-size: 12px;
  color: #6d7080;
  padding: 13px 17px;
`;

export const Img6 = styled.img`
  aspect-ratio: 0.93;
  object-fit: auto;
  object-position: center;
  width: 13px;
`;

export const Div33 = styled.div`
  font-family: Poppins, sans-serif;
  font-weight: 400;
  flex-grow: 1;
  flex-basis: auto;
`;

export const Div34 = styled.div`
  border-radius: 4px;
  border: 1px solid #79cc72;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  color: #79cc72;
  font-weight: 400;
  padding: 1px 1px 1px 13px;
  box-sizing: border-box;

  height: 40px;
`;

export const Div35 = styled.div`
  margin: auto 0;
  font: 13px Poppins, sans-serif;
`;

export const Div36 = styled.div`
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 0px 4px 4px 0px;
  background-color: rgba(121, 204, 114, 0.15);
  aspect-ratio: 0.9;
  white-space: nowrap;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font: 20px Poppins, sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export const Div38 = styled.div`
  font-family: Poppins, sans-serif;
  margin: auto 0;
`;

export const Img7 = styled.img`
  aspect-ratio: 0.9;
  object-fit: auto;
  object-position: center;
  width: 35px;
  border-radius: 0px 4px 4px 0px;
`;

export const Div39 = styled.div`
  color: #fff;
  text-align: center;
  margin-top: 61px;
  font: 400 13px Rubik, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

export const StyledContentHeaderCard = styled.div`
  width: 100%;
  border-bottom: 2px solid #e9ebec;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 16px;
  }
`;

export const StyledButtonDonwload = styled.div`
  width: 240px;
  border-radius: 4px;
  background-color: #79cc72;
  display: flex;
  padding-left: 13px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  margin: auto 0;
  height: 40px;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    background-color: #69bc62;
  }
`;

export const StyledButtonDownloadIcon = styled.div`
  background-color: #d7ffd3;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
`;
