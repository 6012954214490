import React, { FC, ReactElement } from 'react';
import {
  StyleContentChangeStatus,
  StyleContentChangeStatusContent,
  StyledCardDeleteRequest,
} from './styles';

import { Button } from '../Button';

interface Props {
  id: string;
  title?: string;
  buttonText?: string;
  handleDelete: (id: string) => void;
}

const CardDeleteRequest: FC<Props> = ({
  id,
  handleDelete,
  title = 'Cuidado, tenha certeza a executar a ação abaixo pois ela é irreversível',
  buttonText = 'Deletar solicitação',
}): ReactElement => {
  return (
    <StyledCardDeleteRequest>
      <StyleContentChangeStatus>
        <StyleContentChangeStatusContent>
          {title}
        </StyleContentChangeStatusContent>
        <div>
          <Button
            onClick={() => handleDelete(id)}
            style={{
              borderColor: '#b2102f',
              color: '#b2102f',
              marginTop: 0,
            }}
          >
            {buttonText}
          </Button>
        </div>
      </StyleContentChangeStatus>
    </StyledCardDeleteRequest>
  );
};

export default CardDeleteRequest;
