import styled from 'styled-components';

export const StyledRoot = styled('div')(() => ({
  flex: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  paddingTop: 24,
}));

export const StyledLabel = styled.div`
  font-family: Rubik, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  color: #79cc72;
  font-family: Poppins, sans-serif;
  font-weight: 300;
  font-size: 14px;
`;

export const StyledBox = styled('div')(() => ({
  border: '1px solid #ddd',
  borderRadius: 8,
  padding: '4px',
  boxSizing: 'border-box',
  borderStyle: 'dashed',

  maxHeight: 300,
  overflowY: 'auto',
}));

export const StyledItem = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: 8,
  padding: '4px 8px',
  boxSizing: 'border-box',
  transition: '0.3s',

  '&:hover': {
    background: '#f0f0f0',
  },
}));

export const StyledText = styled('div')(() => ({
  fontSize: 14,
  fontWeight: 300,
}));

export const StyledIcon = styled('div')(() => ({
  cursor: 'pointer',
  padding: 4,
  boxSizing: 'border-box',
  borderRadius: 50,
}));
