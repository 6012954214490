import styled from 'styled-components';

export const StyledCardStatus = styled('div')(({ onClick }) => ({
  boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.25)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  padding: '16px',
  position: 'relative',
  cursor: onClick ? 'pointer' : undefined,
  transition: '0.4s',
  fontFamily: 'Inter',
  color: '#79cc72',
  gap: 24,
  display: 'flex',
  flexDirection: 'column',

  '& > div': {
    paddingBottom: 24,
    borderBottom: '1px solid #ccc',
  },

  '& > div:last-child': {
    paddingBottom: 0,
    borderBottom: 'none',
  },

  '&:hover': {
    transform: onClick ? 'scale(1.01)' : '',
  },

  maxHeight: 500,
  overflowY: 'auto',
}));

export const StyledContentCardInfo = styled('div')({
  color: '#878A99',
  fontWeight: 300,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 6,
  '> div': {
    display: 'flex',
    gap: 4,
  },
  '> div > div': {
    fontWeight: 400,
  },
});

export const StyledAction = styled('div')({
  fontSize: 14,
  width: 75,
  height: 42,
  fontWeight: 500,
  cursor: 'pointer',
  padding: '12px 0px',
  boxSizing: 'border-box',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 12,

  '&:hover': {
    textDecoration: 'underline',
  },
});

export const StyleContentChangeStatus = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 16,
  padding: 16,
  boxSizing: 'border-box',
  // marginBottom: 30,
}));

export const StyleContentChangeStatusContent = styled('div')(() => ({
  // marginTop: 30,
  color: '#777',
}));
