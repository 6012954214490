import { Box, IconButton, Modal } from '@mui/material';
import styled from 'styled-components';

export const StyleBox = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 1200,
  maxWidth: '100%',
  boxShadow: '24px',
  outline: 'none',
  background: '#fff',
  borderRadius: 8,
  boxSizing: 'border-box',
  color: '#777',
  maxHeight: '80vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 16,
  justifyContent: 'center',
  padding: 8,

  '@media (min-width: 600px)': {
    padding: 16,
  },
});

export const FormLoginStyled = styled.form`
  width: 100%;
  height: 100%;
`;

export const Div7 = styled.div`
  font-family: Rubik, sans-serif;
  margin-top: 8px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const StyleContentImage = styled(Box)({
  maxHeight: '60vh',
  display: 'flex',
});

export const StyleContentImageItem = styled.div`
  width: 400px;
  overflow-y: auto;
  max-width: 100%;
`;

export const StyleImage = styled('img')({
  width: '100%',
});

export const StyleIconButton = styled(IconButton)({
  position: 'absolute !important' as any,
  right: 10,
  top: 10,
});

export const StyledModal = styled(Modal)({
  '.MuiBox-root': {
    minWidth: '0 !important',
    width: 1000,
    maxWidth: 'calc(100% - 16px)',
    gap: 16,

    '@media (max-width: 900px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
});

export const StyledModalButton = styled.div`
  position: absolute;
  top: 50%;
  bottom: 50%;
  transform: translateY(-50%);

  &.button-left {
    right: initial;
    left: 10%;
  }

  &.button-right {
    right: 10%;
    left: initial;
  }

  @media (max-width: 1400px) {
    display: flex;
    justifycontent: center;
    alignitems: center;
    width: 50px;
    bottom: 10%;
    top: initial;

    &.button-left {
      left: 20%;
      transform: translate(-50%, 100%);
    }

    &.button-right {
      right: 20%;
      transform: translate(50%, 100%);
    }
  }

  @media (max-width: 900px) {
    bottom: 5%;
  }
`;
