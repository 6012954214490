import * as React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { useFormik } from 'formik';

import {
  StyledForm,
  StyledFormButtons,
  StyledFormInput,
  StyledFormInputs,
  StyledTextArea,
} from './styles';

import { FormProps, initialFormValue, validationSchema } from './schema';
import { ButtonWithIcon } from '../../../NewRequestV3/StepOne/Form/Button';

interface Props {
  isLoading: boolean;
  resetForm?: boolean;
  handleSubmit: (values: FormProps) => void;
}

const FormComments: React.FC<Props> = ({
  isLoading,
  resetForm,
  handleSubmit,
}): React.ReactElement => {
  const formik = useFormik({
    initialValues: initialFormValue,
    validationSchema,
    onSubmit: () => {
      const { values } = formik;

      handleSubmit(values);

      formik.resetForm();
    },
  });

  React.useEffect(() => {
    if (resetForm) {
      formik.resetForm();
    }
  }, [formik, resetForm]);

  const handleChange = (event: any): void => {
    const { value } = event.target;

    formik.setFieldValue('comment', value);
  };

  return (
    <React.Fragment>
      <StyledForm onSubmit={formik.handleSubmit}>
        <StyledFormInputs>
          <StyledFormInput>
            <StyledTextArea
              name="comment"
              value={formik.values.comment}
              onChange={handleChange}
              onBlur={(e: any) => {
                formik.handleBlur(e);
              }}
            />
          </StyledFormInput>
        </StyledFormInputs>

        <StyledFormButtons>
          <ButtonWithIcon
            label="Adicionar comentário"
            icon={<AddIcon />}
            isLoading={isLoading}
            type="submit"
          />
        </StyledFormButtons>
      </StyledForm>
    </React.Fragment>
  );
};

export default FormComments;
