import styled from 'styled-components';

export const Div18 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  border-bottom: 1px solid #79cc7233;
  margin-bottom: 10px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const Div19 = styled.div`
  color: #79cc72;
  font: 500 16px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const Div20 = styled.div`
  color: #79cc72;
  font: 275 12px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const Div25 = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0%;
  flex-direction: column;
  padding-top: 8px;
  color: #878a99;
`;

export const Div28 = styled.div`
  color: #878a99;
  margin-top: 10px;
  font: italic 300 12px Poppins, sans-serif;
`;

export const Div37 = styled.div`
  width: 185px;
  border-radius: 4px;
  background-color: #79cc72;
  display: flex;
  padding-left: 13px;
  // padding-right: 10px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-size: 13px;
  color: #fff;
  font-weight: 500;
  margin: auto 0;
  height: 40px;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    background-color: #69bc62;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const Div38 = styled.div`
  font-family: Poppins, sans-serif;
  margin: auto 0;
`;

export const StyledContentButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  justify-content: flex-end;

  @media (max-width: 480px) {
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
`;

export const StyledButtonBack = styled.div`
  width: 165px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  padding: 0px 12px;
  gap: 20px;
  font-size: 13px;
  color: #79cc72;
  border: 1px solid #79cc72;
  font-weight: 400;
  margin: auto 0;
  height: 40px;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    background-color: #69bc6222;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const StyledContentCheckbox = styled.div`
  color: #333;
  padding: 30px 10px 20px 10px;
  box-sizing: border-box;
`;

export const StyledContentFiles = styled.div`
  color: #878a99;
  margin-top: 5px;
  font: italic 300 10px Poppins, sans-serif;

  display: flex;
  flex-direction: column;
`;

export const StyledContentFilesItem = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLink = styled.a`
  color: #00bcd4;
  text-decoration: none;
  padding-left: 5px;
`;

export const StyledSpanCheckBox = styled.span`
  font-size: 14px;
  line-height: 1;
  font-weight: 300;
  color: #777;
`;
