import styled from 'styled-components';

import { Box } from '@mui/material';

export const StyledBox = styled(Box)({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 400,
  borderRadius: 4,
  background: '#fff',
  padding: 32,
  boxSizing: 'border-box',
  p: 4,
  color: '#878A99',
});

export const FormLoginStyled = styled.form`
  width: 100%;
  height: 100%;
`;

export const Div7 = styled.div`
  font-family: Rubik, sans-serif;
  margin-top: 32px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const Div9 = styled.div`
  font-family: Rubik, sans-serif;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export const StyledContentButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
`;
