import yup from '../../../../utils/yup';

export enum ClientTypeEnum {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
  LOOSE = 'loose',
}
export const initialFormValue = {
  comment: '',
};

export interface FormProps {
  comment: string;
}

export const validationSchema = yup.object({
  comment: yup.string().required('Campo obrigatório'),
});
