import 'react-toastify/dist/ReactToastify.css';

import React, { ReactElement } from 'react';
import { ToastContainer } from 'react-toastify';
// import { HashRouter as Router, Switch, Route } from 'react-router-dom';

import { HashRouter, Route, Routes } from 'react-router-dom';

import HomePage from './pages/Home';
import HomePageV2 from './pages/HomeV2';
import LoginPage from './pages/Login';
import NewRequestPage from './pages/NewRequest';
import NewRequestPageV2 from './pages/NewRequestV2';
import NewRequestPageV3 from './pages/NewRequestV3';
import NotFound from './pages/NotFound';
import PrivateRoute from './routes/PrivateRoute';

import AllRequestPage from './pages/AllRequest';
import ExtractPage from './pages/Extract';
import LandingPage from './pages/LandingPage';
import PartnerLoginPage from './pages/PartnerLogin';
import UsersPage from './pages/Users';
import SettingsPage from './pages/Settings';

const App: React.FC = (): ReactElement => {
  return (
    <React.Fragment>
      <ToastContainer style={{ top: '48px' }} />

      <HashRouter>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/partner" element={<PartnerLoginPage />} />

          <Route
            path="/landing-page"
            element={
              <PrivateRoute>
                <LandingPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/home"
            element={
              <PrivateRoute>
                <HomePageV2 />
              </PrivateRoute>
            }
          />

          <Route
            path="/home-old"
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/new-request-v2"
            element={
              <PrivateRoute>
                <NewRequestPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/new-request"
            element={
              <PrivateRoute>
                <NewRequestPageV3 />
              </PrivateRoute>
            }
          />
          <Route
            path="/new-request-v3"
            element={
              <PrivateRoute>
                <NewRequestPageV2 />
              </PrivateRoute>
            }
          />
          <Route
            path="/all-requests"
            element={
              <PrivateRoute role="admin">
                <AllRequestPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/users"
            element={
              <PrivateRoute role="admin">
                <UsersPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/extract"
            element={
              <PrivateRoute role="admin">
                <ExtractPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/settings"
            element={
              <PrivateRoute role="admin">
                <SettingsPage />
              </PrivateRoute>
            }
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </HashRouter>
    </React.Fragment>
  );
};

export default App;
