import * as React from 'react';

import { Div18, Div19, Div20 } from './styles';

interface Props {
  title: string;
  subtitle: string;
}

const HeaderRequest: React.FC<Props> = ({
  title,
  subtitle,
}): React.ReactElement => {
  return (
    <Div18>
      <Div19>{title}</Div19>
      <Div20>{subtitle}</Div20>
    </Div18>
  );
};

export default HeaderRequest;
