import * as React from 'react';
import { CircularProgress, Modal, Typography } from '@mui/material';
import {
  Div9,
  FormLoginStyled,
  StyledBox,
  StyledContentButtons,
} from './styles';
import SubmitButton, { Button } from '../../../components/Button';

interface Props {
  open: boolean;
  isLoading: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

const ModalDeleteRequest = ({
  open,
  isLoading,
  handleClose,
  handleSubmit,
}: Props): React.ReactElement => {
  const submit = (e: any): void => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <StyledBox>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Deleter solicitação
        </Typography>
        <FormLoginStyled>
          <Div9>Todos os dados dessa solicitação serão apagados.</Div9>

          <StyledContentButtons>
            <div style={{ width: '45%' }}>
              <Button onClick={handleClose} disabled={isLoading}>
                {isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <span>Cancelar</span>
                )}
              </Button>
            </div>
            <div style={{ width: '45%' }}>
              <SubmitButton
                onClick={submit}
                disabled={isLoading}
                style={{ background: '#b2102f' }}
              >
                {isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <span>Deletar</span>
                )}
              </SubmitButton>
            </div>
          </StyledContentButtons>
        </FormLoginStyled>
      </StyledBox>
    </Modal>
  );
};

export default ModalDeleteRequest;
