/* eslint-disable no-param-reassign */
export const formatMoney = (price: string | number): string => {
  if (!price || typeof Number(price) !== 'number') {
    return '';
  }
  return `${Number(price).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })}`;
};

export const removeMaskMoney = (price: string): string => {
  const value = price
    .replaceAll('.', '')
    .replaceAll(',', '')
    .replaceAll('R$', '')
    .replaceAll(' ', '');

  return value;
};

export const moneyMask = (value: string | number): string => {
  if (!value) {
    return 'R$ 0,00';
  }

  value = value.toString().replace('.', '').replace(',', '').replace(/\D/g, '');

  const options = { minimumFractionDigits: 2 };

  const newValue = parseFloat(value) / 100;

  if (Number.isNaN(newValue)) {
    return 'R$ 0,00';
  }

  const result = new Intl.NumberFormat('pt-BR', options).format(newValue);

  return `R$ ${result}`;
};
