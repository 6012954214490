import * as React from 'react';
import { Modal, Typography } from '@mui/material';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import PaidIcon from '@mui/icons-material/Paid';
import {
  Div9,
  FormLoginStyled,
  StyledBox,
  StyledContentButtons,
} from './styles';
import SubmitButton, { Button } from '../../../components/Button';
import { formatMoney } from '../../../utils/money';

interface Props {
  open: boolean;
  data: any;
  handleClose: () => void;
  handleSubmit: (id: string, values: any) => void;
}

const ModalResultPayments = ({
  open,
  data,
  handleClose,
  handleSubmit,
}: Props): React.ReactElement => {
  const submit = (e: any): void => {
    e.preventDefault();
    handleSubmit(data.request.id, { status: 'confirmed' });
  };

  const isDivergent = React.useMemo(() => {
    const difference = Math.abs(data?.request?.total - data?.request?.paid);
    return difference >= 5;
  }, [data]);

  return (
    <Modal open={open} onClose={handleClose}>
      <StyledBox>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Status do pagamento
        </Typography>
        <FormLoginStyled>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: 30,
              gap: 16,
            }}
          >
            <div>
              {isDivergent ? (
                <ReportProblemRoundedIcon
                  htmlColor="orange"
                  fontSize="large"
                  style={{ fontSize: 48 }}
                />
              ) : (
                <PaidIcon
                  htmlColor="green"
                  fontSize="large"
                  style={{ fontSize: 48 }}
                />
              )}
            </div>
            {isDivergent && (
              <Div9>
                <div>
                  Os valores dos comprovantes de pagamentos estão divergentes
                </div>
                <div>
                  <div>
                    Valor da solicitação:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {formatMoney(data.request.total)}
                    </span>
                  </div>
                  <div>
                    Valor dos comprovantes:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {formatMoney(data.request.paid)}
                    </span>
                  </div>
                </div>
              </Div9>
            )}
            {!isDivergent && (
              <Div9>
                Pagamento bem sucedido! Deseja confirmar a solicitação?
              </Div9>
            )}
          </div>

          <StyledContentButtons>
            {isDivergent && (
              <div style={{ width: '45%' }}>
                <Button onClick={handleClose}>
                  <span>Ok</span>
                </Button>
              </div>
            )}

            {!isDivergent && (
              <React.Fragment>
                <div style={{ width: '45%' }}>
                  <Button onClick={handleClose}>
                    <span>Não</span>
                  </Button>
                </div>
                <div style={{ width: '45%' }}>
                  <SubmitButton onClick={submit}>
                    <span>Sim</span>
                  </SubmitButton>
                </div>
              </React.Fragment>
            )}
          </StyledContentButtons>
        </FormLoginStyled>
      </StyledBox>
    </Modal>
  );
};

export default ModalResultPayments;
