import { moneyMask } from '../../../../utils/money';
import yup from '../../../../utils/yup';

export enum ClientTypeEnum {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
  LOOSE = 'loose',
}
export const initialFormValue = {
  identification: '',
  phone: '',
  name: '',
  email: '',
  miles: '0',
  valueMile: moneyMask('0'),
};

export interface FormProps {
  identification: string;
  phone: string;
  name: string;
  email: string;
  miles: number;
  valueMile: number;
}

export const validationSchema = yup.object({
  identification: yup
    .string()
    .validCpfOrCnpj('CPF inválido')
    .required('Campo obrigatório'),
  phone: yup
    .string()
    .min(15, 'Campo inválido')
    .max(15, 'Campo inválido')
    .required('Campo obrigatório'),
  name: yup.string().required('Nome obrigatório'),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  miles: yup.string().required('Campo obrigatório'),
  valueMile: yup.string().required('Campo obrigatório'),
  // valueMile: yup
  //   .string()
  //   .required('Campo obrigatório')
  //   .test(
  //     'is-valid-number',
  //     'O valor deve ser entre R$ 20,00 e R$ 35,00',
  //     (value) => {
  //       if (!value) return false;
  //       const parsedValue = parseFloat(value.replace('R$', ''));
  //       // eslint-disable-next-line no-restricted-globals
  //       return !isNaN(parsedValue) && parsedValue >= 20 && parsedValue <= 35;
  //     }
  //   ),
});
