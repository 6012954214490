import React, { ReactElement } from 'react';
import { CircularProgress } from '@mui/material';
import {
  Div37,
  Div38,
  Div39,
  StyledButton,
  StyledSubmitButton,
} from './styles';

interface SubmitButtonProps {
  children: ReactElement | string;
  onClick?: (event: any) => void;
  disabled?: boolean;
  style?: any;
}

export const SubmitButton = ({
  children,
  onClick,
  disabled,
  style,
}: SubmitButtonProps): any => {
  return (
    <StyledSubmitButton
      onClick={onClick}
      type="submit"
      disabled={disabled}
      style={style}
    >
      {children}
    </StyledSubmitButton>
  );
};

export const Button = ({
  children,
  onClick,
  disabled,
  style,
}: SubmitButtonProps): any => {
  return (
    <StyledButton onClick={onClick} disabled={disabled} style={style}>
      {children}
    </StyledButton>
  );
};

interface ButtonWithIconProps {
  label: string;
  isLoading?: boolean;
  onClick?: () => void;
  icon?: ReactElement;
  type?: 'submit' | 'reset' | 'button';
}

export const ButtonWithIcon = ({
  isLoading,
  label,
  icon,
  onClick,
  type,
}: ButtonWithIconProps): any => {
  return (
    <Div37
      onClick={() => !isLoading && onClick && onClick()}
      type={type}
      disabled={isLoading}
    >
      <Div38>{label}</Div38>
      {isLoading && (
        <Div39>
          <CircularProgress color="inherit" size={20} />
        </Div39>
      )}
      {!isLoading && icon && <Div39>{icon}</Div39>}
    </Div37>
  );
};

export default SubmitButton;
