import styled, { keyframes } from 'styled-components';

export const StyledRoot = styled.div`
  height: 96px;
`;

export const StyledHeader = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 10;
  background: linear-gradient(
    60deg,
    rgba(21, 110, 121, 1) 0%,
    rgba(121, 204, 114, 1) 100%
  );
  color: white;
  z-index: 11;
`;

export const StyledLogo = styled.svg`
  width: 50px;
  fill: white;
  padding-right: 15px;
  display: inline-block;
  vertical-align: middle;
`;

export const StyledInnerHeader = styled.div`
  height: 8vh;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 24px 16px 0;
  text-align: center;
  box-sizing: border-box;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  &.menu-opened {
    height: auto;

    button svg {
      transform: rotate(90deg);
    }
  }

  @media (min-width: 1000px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StyledFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const StyledWaves = styled.svg`
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: auto;
  max-height: 40px;
`;

export const StyledContent = styled.div`
  position: relative;
  height: 20vh;
  text-align: center;
  background-color: white;
`;

export const StyledToggleMenuButton = styled.button`
  position: absolute;
  top: 20px;
  right: 16px;
  appearance: none;
  background: transparent;
  border: 1px solid white;
  color: white;
  padding: 8px;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    transition: transform 250ms;
  }

  @media (min-width: 1000px) {
    display: none;
  }
`;

interface StyledMenuContainerProps {
  isOpen: boolean;
}

export const StyledMenuContainer = styled.div<StyledMenuContainerProps>`
  display: flex;
  flex-direction: column;
  margin: 16px auto 0;

  ${({ isOpen }) => `
    display: ${isOpen ? 'flex' : 'none'};
  `}

  @media (min-width: 1000px) {
    flex-direction: row;
    margin: 0;
    display: flex;
    justify-content: center;
    gap: 8px;
    font-size: 16px;
    line-height: 130%;
    flex-wrap: wrap;
  }
`;

interface Props {
  selected: boolean;
}

export const StyledMenuItem = styled('button')<Props>(({ selected }) => ({
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: 'Poppins, sans-serif',
  color: '#fff',
  fontSize: '16px',
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  fontWeight: 600,
  zIndex: 10,

  padding: '10px',
  boxSizing: 'border-box',
  borderBottom: selected ? '2px solid #fff' : '2px solid transparent',
  transition: '0.4s',

  '&:hover': {
    borderBottom: '2px solid #fff',
  },
}));

const moveForever = keyframes`
  0% {
    transform: translate3d(-90px,0,0);
  }
  100% {
    transform: translate3d(85px,0,0);
  }
`;

export const StyledParallax = styled.g`
  & use {
    // animation: ${moveForever} 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
    backgroud: red;
  }

  & use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
    background: red;
  }
  & use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
    background: blue;
  }
  & use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
    background: yellow;
  }
  & use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
  }
`;
