import * as React from 'react';

import HomeIcon from '@mui/icons-material/Home';

import GppGoodIcon from '@mui/icons-material/GppGood';

// import Lottie from 'react-lottie';
import { Typography } from '@mui/material';
import { Div25, Div28, Div37, Div38, Div32 } from './styles';

// import animationData from '../success.json';
import { formatDate } from '../../../utils/date';
import HeaderRequest from '../../../components/HeaderRequest';

// const defaultOptions = {
//   loop: true,
//   autoplay: true,
//   animationData,
//   rendererSettings: {
//     preserveAspectRatio: 'xMidYMid slice',
//   },
// };

interface SuccessRequest {
  id: string;
  date: Date;
}

interface Props {
  successData: SuccessRequest | null;
  handleFinishRequest: () => void;
}

const StepFourRequest: React.FC<Props> = ({
  successData,
  handleFinishRequest,
}): React.ReactElement => {
  return (
    <React.Fragment>
      <HeaderRequest
        title="4. Confirmação"
        subtitle="Solicitação efetuada com sucesso"
      />

      <Div25>
        <Div28>
          <div>Finalizada em:</div> {formatDate(successData?.date)}
        </Div28>
        <Div28>
          <div>ID da solicitação:</div> {successData?.id}
        </Div28>
      </Div25>
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '48px 0px',
            gap: 24,
          }}
        >
          <Typography
            component="h2"
            fontSize={16}
            fontWeight="500"
            style={{ width: 160, textAlign: 'center' }}
            color="#878A99"
          >
            Solicitação concluída com sucesso!
          </Typography>

          <GppGoodIcon
            htmlColor="#79CC72"
            fontSize="large"
            style={{ width: 55, height: 55 }}
          />
        </div>
      </div>
      {/* <div>
        <Lottie options={defaultOptions} height={150} width={150} />
      </div> */}
      <Div32>
        <Div37 onClick={() => handleFinishRequest()}>
          <Div38>Página inicial</Div38>
          <HomeIcon />
        </Div37>
      </Div32>
    </React.Fragment>
  );
};

export default StepFourRequest;
