import React, { HTMLInputTypeAttribute } from 'react';
import styled from 'styled-components';

const InputContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
`;

const InputField = styled('input')({
  borderRadius: '4px',
  border: '1px solid #ced4da',
  // marginTop: '13px',
  justifyContent: 'center',
  alignItems: 'start',
  // color: '#878a99',
  whiteSpace: 'nowrap',
  padding: '13px 60px 13px 15px',
  backgroundColor: '#fff',
  width: '100%',
  fontSize: '14px',
  fontFamily: 'Poppins, sans-serif',

  '@media (max-width: 991px)': {
    whiteSpace: 'initial',
    maxWidth: '100%',
    paddingRight: '20px',
  },

  '&::placeholder': {
    color: 'rgba(21, 110, 121, 0.5)',
  },
  color: 'rgba(21, 110, 121, 1)',

  '&:disabled': {
    background: '#ccc !important',
    pointerEvents: 'none',
  },
});
// const InputField = styled.input`
//   font-family: Rubik, sans-serif;
//   border-radius: 4px;
//   border: 1px solid #ced4da;
//   margin-top: 13px;
//   justify-content: center;
//   align-items: start;
//   color: #878a99;
//   white-space: nowrap;
//   padding: 13px 60px 13px 15px;
//   @media (max-width: 991px) {
//     white-space: initial;
//     max-width: 100%;
//     padding-right: 20px;
//   }
//   background-color: #fff;
//   width: 100%;
//   font-size: 14px;
//   font-family: Poppins, sans-serif;

//   ::placeholder: {
//     color: red;
//   }
// `;

interface SimpleInputProps {
  placeholder?: string;
  value?: string;
  onChange?: (event?: any) => void;
  name?: string;
  type?: HTMLInputTypeAttribute;
  disabled?: boolean;
}

const SimpleInput = ({
  placeholder,
  value,
  onChange,
  name,
  disabled,
  type = 'text',
}: SimpleInputProps): any => {
  return (
    <InputContainer>
      <InputField
        type={type}
        disabled={disabled}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
      />
    </InputContainer>
  );
};

export default SimpleInput;
