import styled from 'styled-components';

export const StyledCardRequest = styled('div')(({ onClick }) => ({
  boxShadow: '0px 0px 8px 1px rgba(0, 0, 0, 0.25)',
  backgroundColor: '#fff',
  borderRadius: '8px',
  padding: '16px',
  position: 'relative',
  cursor: onClick ? 'pointer' : undefined,
  transition: '0.4s',
  fontFamily: 'Inter',
  color: '#79cc72',

  '&:hover': {
    transform: onClick ? 'scale(1.01)' : '',
  },
}));

export const StyledContentCardInfo = styled('div')({
  color: '#878A99',
  fontWeight: 300,
  display: 'flex',
  flexDirection: 'column',
  gap: 6,
  '> div': {
    display: 'flex',
    gap: 4,
  },
  '> div > div': {
    fontWeight: 400,
  },
});

export const StyledContentCard = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',

  '@media (max-width: 600px)': {
    flexDirection: 'column',
  },
});

export const StyledContentPaymentField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
