import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { Div7, FormLoginStyled, StyleBox } from './styles';
import SimpleInput from './Input';
import useAuthenticate from '../../../recoil/hooks/authenticate';
import { RequestStatusEnum } from '../utils';
import SimpleSelect from '../../Login/Select';
import SubmitButton from '../../../components/Button';
import { updateRequestsStatus } from '../../../api/requestV2';

interface Props {
  open: boolean;
  handleClose: () => void;
  getData: () => void;
}

const yesterday = new Date();

yesterday.setDate(yesterday.getDate() - 1);

const initialState = {
  startDate: yesterday.toISOString().split('T')[0],
  endDate: new Date().toISOString().split('T')[0],
  status: RequestStatusEnum.all,
};

export default function ModalChangeStatusRequest({
  open,
  handleClose,
  getData,
}: Props): React.ReactElement {
  const { authenticate } = useAuthenticate();

  const [formData, setFormData] = React.useState(initialState);

  const [isLoading, setLoading] = React.useState(false);

  const handleChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      event.preventDefault();
      setLoading(true);

      const { startDate, endDate, status } = formData;

      if (!startDate?.length || !endDate?.length) {
        toast('Por favor, preencha os campos antes de submeter o formulário.', {
          type: 'error',
        });
        return;
      }

      await updateRequestsStatus(
        startDate,
        endDate,
        status,
        authenticate.token
      );

      getData();

      toast('Alteração realizada com sucesso!', {
        type: 'success',
      });
      handleClose();
      setFormData(initialState);
    } catch (error: any) {
      const message =
        'Não foi possível atualizar as solicitações. Por favor, tente novamente!';
      toast(message, { type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      keepMounted={false}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <StyleBox>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Mudar status
          </Typography>
          <FormLoginStyled>
            <Div7>Novo status</Div7>
            <SimpleSelect
              name="status"
              value={formData.status}
              onChange={handleChange}
              options={[
                {
                  value: RequestStatusEnum.waitingCredit,
                  label: 'Confirmado para Aguardando crédito',
                },
                {
                  value: RequestStatusEnum.completed,
                  label: 'Aguardando crédito para Concluído',
                },
              ]}
            />
            <Div7>Data de ínicio</Div7>
            <SimpleInput
              placeholder={'Data de início'}
              name="startDate"
              type="date"
              max={new Date().toLocaleDateString('fr-ca')}
              onChange={handleChange}
              value={formData.startDate}
            />
            <Div7>Data de fim</Div7>
            <SimpleInput
              placeholder={'Data de fim'}
              name="endDate"
              type="date"
              min={formData.startDate}
              max={new Date().toLocaleDateString('fr-ca')}
              onChange={handleChange}
              value={formData.endDate}
            />

            <SubmitButton onClick={handleSubmit} disabled={isLoading}>
              {isLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <span>Alterar status</span>
              )}
            </SubmitButton>
          </FormLoginStyled>
        </StyleBox>
      </Fade>
    </Modal>
  );
}
