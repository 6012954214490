import React, { FC, ReactElement, useState } from 'react';
import { CircularProgress } from '@mui/material';
import {
  StyledAction,
  StyledActions,
  StyledCardPayments,
  StyledContentCardInfo,
  StyledItem,
} from './styles';

import useAuthenticate from '../../recoil/hooks/authenticate';

interface Props {
  items: any[];
  onClick?: () => void;
  handleDownload: (fileId: string, filename: string) => Promise<void>;
  handlePreview: (fileId: string, filename: string, payments: any[]) => void;
}

const CardPayments: FC<Props> = ({
  items,
  handleDownload,
  handlePreview,
}): ReactElement => {
  const [loadingDownload, setLoadingDownload] = useState('');
  const { authenticate } = useAuthenticate();

  const isAdmin = React.useMemo(
    () => ['admin'].includes(authenticate?.user?.role),
    [authenticate]
  );

  const downloadFile = async (id: string, name: string) => {
    setLoadingDownload(id);
    await handleDownload(id, name);

    setLoadingDownload('');
  };

  return (
    <StyledCardPayments>
      {items?.map((item) => (
        <StyledItem key={item.id} style={{}}>
          <StyledContentCardInfo>
            <div
              style={{
                width: '100%',
                textAlign: 'center',
              }}
            >
              {item.id.substring(17, item.id.length)} - {item.name}
            </div>
            {isAdmin && <div>Transação: {item.transaction || '-'}</div>}
          </StyledContentCardInfo>
          <StyledActions
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 16,
            }}
          >
            <StyledAction
              onClick={() => handlePreview(item.id, item.name, items)}
            >
              Visualizar
            </StyledAction>

            <StyledAction onClick={() => downloadFile(item.id, item.name)}>
              {loadingDownload === item.id ? (
                <div>
                  <CircularProgress color="inherit" size={16} />
                </div>
              ) : (
                <div>Download</div>
              )}
            </StyledAction>
          </StyledActions>
        </StyledItem>
      ))}
    </StyledCardPayments>
  );
};

export default CardPayments;
