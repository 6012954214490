import React, { FC, ReactElement } from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { StyledCardComments, StyledContentCardInfo } from './styles';
import { formatDate } from '../../utils/date';

interface Props {
  items?: any[];
  handleDelete?: (item: any) => void;
}

const CardComments: FC<Props> = ({ items, handleDelete }): ReactElement => {
  const sortItems = (items?: any[]): any[] => {
    if (!items?.length) {
      return [];
    }
    return items.sort((a, b) => {
      const dateA = new Date(a.createdAt).getTime();
      const dateB = new Date(b.createdAt).getTime();

      return dateB - dateA;
    });
  };
  return (
    <StyledCardComments>
      {sortItems(items)?.map((item, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <StyledContentCardInfo>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div>
                {item.user} - {formatDate(item.createdAt)}
              </div>
              {handleDelete && (
                <div
                  style={{
                    color: 'red',
                    paddingTop: 0,
                    boxSizing: 'border-box',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleDelete(item)}
                >
                  <DeleteOutlineIcon />
                </div>
              )}
            </div>
            <div
              style={{
                marginTop: 8,
                padding: 8,
                boxSizing: 'border-box',
                border: '1px solid #ccc',
                background: '#efefef',
                borderRadius: 8,
                width: '100%',
                color: '#232634',
              }}
              dangerouslySetInnerHTML={{
                __html: item.comment.replaceAll('\n', '<br />'),
              }}
            >
              {/* {item.comment} */}
            </div>
          </StyledContentCardInfo>
        </div>
      ))}

      {!items?.length && (
        <div style={{ color: '#878A99' }}>Nenhum comentário adicionado</div>
      )}
    </StyledCardComments>
  );
};

export default CardComments;
