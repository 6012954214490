/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { CircularProgress, IconButton, Modal, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import Close from '@mui/icons-material/Close';
import { StyledBox } from './styles';

import CardRequest from '../../../components/CardRequest';
import CardPayments from '../../../components/CardPayments';
import CardAccounts from '../../../components/CardAccounts';
import useAuthenticate from '../../../recoil/hooks/authenticate';
import {
  cancelRequest,
  createComment,
  deleteComment,
  deleteRequestV2,
  getRequestDetailsV2,
  updateRequestStatusV2,
} from '../../../api/requestV2';
import {
  downloadRequestAttachmentByUser,
  previewRequestAttachmentByUser,
} from '../../../api/request';
import ModalPreviewImage from '../../../components/ModalPreviewImage';
import { getAllExtract, vinculateExtract } from '../../../api/extract';
import CardStatus from '../../../components/CardStatus';
import CardDeleteRequest from '../../../components/CardDeleteRequest';
import ModalDeleteRequest from '../ModalDeleteRequest';
import ModalResultPayments from '../ModalResultPayments';
import ModalCancelRequest from '../ModalCancelRequest';
import { RequestStatusEnum } from '../utils';
import CardComments from '../../../components/CardComments';
import FormComments from './FormComments';
import ModalDeleteComment from '../ModalDeleteComment';

interface Props {
  id: string;
  open: boolean;
  onClose: () => void;
  updateData: () => void;
}

const ModalDetails: React.FC<Props> = ({
  open,
  id,
  onClose,
  updateData,
}): React.ReactElement => {
  const { authenticate } = useAuthenticate();

  const [isLoading, setLoading] = React.useState(true);

  const [isLoadingPreviewImage, setLoadingPreviewImage] = React.useState(true);

  const [data, setData] = React.useState<any>(null);

  const [dataExtract, setDataExtract] = React.useState([]);

  const isAdmin = React.useMemo(
    () => ['admin'].includes(authenticate?.user?.role),
    [authenticate]
  );

  const [modalPreview, setModalPreview] = React.useState({
    open: false,
    fileId: '',
    url: '',
    typeFile: '',
    payments: [],
  });

  const [modalResult, setModalResult] = React.useState({
    open: false,
    data: {},
  });

  const [modalDeleteRequest, setModalDeleteRequest] = React.useState({
    open: false,
    isLoading: false,
    requestId: '',
  });

  const [modalCancelRequest, setModalCancelRequest] = React.useState({
    open: false,
    isLoading: false,
    requestId: '',
  });

  const [modalDeleteComment, setModalDeleteComment] = React.useState({
    open: false,
    isLoading: false,
    commentId: '',
  });

  const getDataExtract = React.useCallback(
    async (search?: string) => {
      try {
        const data = await getAllExtract(authenticate.token, search, 'active');

        setDataExtract(data.data);
      } catch (error) {
        toast(
          'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
          {
            type: 'error',
          }
        );
      }
    },
    [authenticate.token]
  );

  React.useEffect(() => {
    if (authenticate?.isAuthenticate && authenticate?.user?.role === 'admin') {
      getDataExtract();
    }
  }, [authenticate, getDataExtract]);

  const handlePreviewFile = async (
    fileId: string,
    filename: string,
    payments: any
  ): Promise<void> => {
    try {
      setLoadingPreviewImage(true);
      setModalPreview({
        fileId,
        open: true,
        url: '',
        typeFile: '',
        payments,
      });

      const { urlFile, typeFile } = await previewRequestAttachmentByUser(
        fileId,
        authenticate.token
      );

      setModalPreview({
        fileId,
        open: true,
        url: urlFile,
        typeFile,
        payments,
      });
    } catch (error) {
      toast(
        'Não foi possível visualizar a imagem. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    } finally {
      setLoadingPreviewImage(false);
    }
  };

  const handleDownloadFile = async (
    fileId: string,
    filename: string
  ): Promise<void> => {
    try {
      await downloadRequestAttachmentByUser(
        fileId,
        filename,
        authenticate.token
      );
    } catch (error) {
      toast(
        'Não foi possível realizar o download. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    }
  };

  const handleDeleteRequest = (id: string) => {
    setModalDeleteRequest({
      open: true,
      isLoading: false,
      requestId: id,
    });
  };

  const handleCancelRequest = (id: string) => {
    setModalCancelRequest({
      open: true,
      isLoading: false,
      requestId: id,
    });
  };

  const handleSubmitDeleteRequest = async (): Promise<void> => {
    setModalDeleteRequest({ ...modalDeleteRequest, isLoading: true });
    try {
      await deleteRequestV2(authenticate.token, modalDeleteRequest.requestId);

      setModalDeleteRequest({
        ...modalDeleteRequest,
        open: false,
        isLoading: false,
      });

      toast('Solicitação deletada com sucesso!', {
        type: 'success',
      });

      setTimeout(() => {
        updateData();
        onClose();
      }, 500);
    } catch (error) {
      setModalDeleteRequest({
        ...modalDeleteRequest,
        isLoading: false,
      });
      toast(
        'Não foi possível deletar a solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    }
  };

  const getDetails = React.useCallback(
    async (isLoading = true) => {
      if (id) {
        try {
          setLoading(isLoading);

          const data = await getRequestDetailsV2(authenticate.token, id);

          setData(data);

          const allPaymentsChecked = data.payments.every((item: any) =>
            Boolean(item.transaction)
          );

          if (data.request.status === 'analysing' && allPaymentsChecked) {
            setModalResult({
              open: true,
              data,
            });
          }
        } catch (error) {
          toast(
            'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
            {
              type: 'error',
            }
          );
          onClose();
        } finally {
          setLoading(false);
        }
      }
    },
    [authenticate.token, id, onClose]
  );

  const handleSubmitCancelRequest = async (): Promise<void> => {
    setModalCancelRequest({ ...modalCancelRequest, isLoading: true });
    try {
      await cancelRequest(authenticate.token, modalCancelRequest.requestId);

      setModalCancelRequest({
        ...modalCancelRequest,
        open: false,
        isLoading: false,
      });

      toast('Solicitação cancelada com sucesso!', {
        type: 'success',
      });

      setTimeout(() => {
        updateData();
        getDetails(false);
      }, 500);
    } catch (error: any) {
      let message =
        'Não foi possível cancelar a solicitação. Por favor, tente novamente!';

      if (error?.response?.status === 409) {
        message =
          'O status da solicitação mudou. Por favor, atualize a página.';
      }

      toast(message, {
        type: 'error',
      });
      setModalCancelRequest({
        ...modalCancelRequest,
        isLoading: false,
      });
    }
  };

  const handleSubmitDeleteComment = async (): Promise<void> => {
    setModalDeleteComment({ ...modalDeleteComment, isLoading: true });
    try {
      await deleteComment(
        authenticate.token,
        data.request.id,
        modalDeleteComment.commentId
      );

      setModalDeleteComment({
        ...modalDeleteComment,
        open: false,
        isLoading: false,
      });

      toast('Comentário deletado com sucesso!', {
        type: 'success',
      });

      setTimeout(() => {
        updateData();
        getDetails(false);
      }, 100);
    } catch (error: any) {
      const message =
        'Não foi possível cancelar a solicitação. Por favor, tente novamente!';

      toast(message, {
        type: 'error',
      });
      setModalDeleteComment({
        ...modalDeleteComment,
        isLoading: false,
      });
    }
  };

  const handleSubmitUpdateRequestStatus = async (
    id: string,
    values: any
  ): Promise<void> => {
    try {
      const oldStatus = data.request.status;
      await updateRequestStatusV2(
        authenticate.token,
        id,
        values.status,
        oldStatus
      );

      const allPaymentsChecked = data.payments.every((item: any) =>
        Boolean(item.transaction)
      );

      if (
        oldStatus === 'pending' &&
        values.status === 'analysing' &&
        !allPaymentsChecked
      ) {
        const paymentToShow = data.payments.find(
          (item: any) => !item.transaction
        );
        handlePreviewFile(paymentToShow.id, paymentToShow.name, data.payments);
      }

      toast('Status da solicitação atualizado com sucesso!', {
        type: 'success',
      });

      setTimeout(() => {
        updateData();
        // onClose();
        getDetails(false);
      }, 200);

      setModalResult({ ...modalResult, open: false });
    } catch (error: any) {
      let message =
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!';

      if (error?.response?.status === 409) {
        message =
          'O status da solicitação foi alterado por outro usuário. Por favor, atualize a página.';
      }
      toast(message, {
        type: 'error',
      });
    }
  };

  const handleSelectExtract = async (id: string, isNext?: boolean) => {
    try {
      await vinculateExtract(authenticate.token, modalPreview.fileId, id);

      setTimeout(() => {
        getDataExtract();
        updateData();
        getDetails(false);

        if (!isNext) {
          setModalPreview({
            ...modalPreview,
            open: false,
          });
        }
      }, 200);

      toast('Comprovante vinculado com sucesso!', {
        type: 'success',
      });
    } catch (error) {
      toast(
        'Não foi possível realizar essa ação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    }
  };

  React.useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';

      getDetails();
    } else {
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleSubmitComment = async (values: any) => {
    try {
      await createComment(authenticate.token, data.request.id, values.comment);

      setTimeout(() => {
        getDetails(false);
        updateData();
      }, 100);
    } catch (error) {
      toast(
        'Não foi possível realizar sua solicitação. Por favor, tente novamente!',
        {
          type: 'error',
        }
      );
    }
  };

  const handleDeleteComment = async (item: any) => {
    setModalDeleteComment({
      open: true,
      isLoading: false,
      commentId: item._id || item.id,
    });
  };

  if (isLoading) {
    return (
      <Modal open={open}>
        <StyledBox>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              height: '100%',
              gap: 24,
              fontFamily: 'Inter',
            }}
          >
            <CircularProgress />
            <div>Carregando...</div>
          </div>
        </StyledBox>
      </Modal>
    );
  }

  return (
    <React.Fragment>
      <Modal open={open}>
        <StyledBox>
          <div style={{ position: 'absolute', top: 0, right: 0 }}>
            <IconButton onClick={onClose}>
              <Close color="error" />
            </IconButton>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 24 }}>
            <Typography
              style={{
                fontFamily: 'Inter',
                color: '#79cc72',
                fontSize: 18,
                fontWeight: 'bold',
              }}
            >
              Detalhes da solicitação
            </Typography>
            <CardRequest item={data.request} hiddenComments />

            {isAdmin && data?.request?.status !== RequestStatusEnum.canceled && (
              <React.Fragment>
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    color: '#79cc72',
                    fontSize: 18,
                    fontWeight: 'bold',
                  }}
                >
                  Status da solicitação
                </Typography>
                <CardStatus
                  item={data.request}
                  handleSubmit={handleSubmitUpdateRequestStatus}
                />
              </React.Fragment>
            )}

            <Typography
              style={{
                fontFamily: 'Inter',
                color: '#79cc72',
                fontSize: 18,
                fontWeight: 'bold',
              }}
            >
              Comprovantes de pagamento
            </Typography>
            <CardPayments
              items={data.payments}
              handlePreview={handlePreviewFile}
              handleDownload={handleDownloadFile}
            />

            <Typography
              style={{
                fontFamily: 'Inter',
                color: '#79cc72',
                fontSize: 18,
                fontWeight: 'bold',
              }}
            >
              Pontos por titular
            </Typography>
            <CardAccounts items={data.accounts} />

            {isAdmin && (
              <React.Fragment>
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    color: '#79cc72',
                    fontSize: 18,
                    fontWeight: 'bold',
                  }}
                >
                  Comentários
                </Typography>
                <FormComments
                  handleSubmit={handleSubmitComment}
                  isLoading={false}
                />
                <CardComments
                  items={data.request.comments}
                  handleDelete={handleDeleteComment}
                />
              </React.Fragment>
            )}

            {data.request.status === RequestStatusEnum.pending && (
              <React.Fragment>
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    color: '#79cc72',
                    fontSize: 18,
                    fontWeight: 'bold',
                  }}
                >
                  Cancelar solicitação
                </Typography>
                <CardDeleteRequest
                  id={data.request.id}
                  title="Caso deseje cancelar esta solicitação, você pode solicitar o cancelamento agora. Ao cancelar nenhuma ação adicional será realizada."
                  buttonText="Cancelar solicitação"
                  handleDelete={handleCancelRequest}
                />
              </React.Fragment>
            )}

            {isAdmin && (
              <React.Fragment>
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    color: '#f00',
                    fontSize: 18,
                    fontWeight: 'bold',
                  }}
                >
                  Dangerous area
                </Typography>
                <CardDeleteRequest
                  id={data.request.id}
                  handleDelete={handleDeleteRequest}
                />
              </React.Fragment>
            )}
          </div>
        </StyledBox>
      </Modal>

      <ModalPreviewImage
        open={modalPreview.open}
        fileId={modalPreview.fileId}
        url={modalPreview.url}
        typeFile={modalPreview.typeFile}
        payments={modalPreview.payments}
        isLoading={isLoadingPreviewImage}
        handleClose={() => setModalPreview({ ...modalPreview, open: false })}
        handlePreviewFile={handlePreviewFile}
        handleSelectExtract={handleSelectExtract}
        extracts={dataExtract}
      />

      <ModalDeleteRequest
        open={modalDeleteRequest.open}
        isLoading={modalDeleteRequest.isLoading}
        handleClose={() =>
          setModalDeleteRequest({ ...modalDeleteRequest, open: false })
        }
        handleSubmit={handleSubmitDeleteRequest}
      />

      <ModalCancelRequest
        open={modalCancelRequest.open}
        isLoading={modalCancelRequest.isLoading}
        handleClose={() =>
          setModalCancelRequest({ ...modalCancelRequest, open: false })
        }
        handleSubmit={handleSubmitCancelRequest}
      />

      <ModalDeleteComment
        open={modalDeleteComment.open}
        isLoading={modalDeleteComment.isLoading}
        handleClose={() =>
          setModalDeleteComment({ ...modalDeleteComment, open: false })
        }
        handleSubmit={handleSubmitDeleteComment}
      />

      <ModalResultPayments
        open={modalResult.open}
        data={modalResult.data}
        handleClose={() => setModalResult({ ...modalResult, open: false })}
        handleSubmit={handleSubmitUpdateRequestStatus}
      />
    </React.Fragment>
  );
};

export default ModalDetails;
