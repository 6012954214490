import styled from 'styled-components';

export const Div28 = styled.div`
  color: #878a99;
  font: italic 300 12px Poppins, sans-serif;
`;

export const StyledRodape = styled.div`
  font-family: Rubik, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  color: #79cc72;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 14px;
`;

export const StyledLabel = styled.div`
  font-family: Rubik, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  color: #79cc72;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 14px;
`;

export const StyledError = styled.div`
  color: #cc0000;
  font-family: Poppins, sans-serif;
  font-weight: 300;
  font-size: 10px;
  padding-top: 4px;
  padding-left: 4px;
  box-sizing: border-box;
`;

export const StyledForm = styled('form')(({ hidden }) => ({
  gap: 24,
  display: hidden ? 'none' : 'flex',
  flexDirection: 'column',
  paddingTop: 24,
}));

export const StyledFormInputs = styled('div')(() => ({
  gap: 24,
  display: 'flex',

  '@media (max-width: 600px)': {
    flexDirection: 'column',
  },
}));

export const StyledFormInput = styled('div')(() => ({
  flex: 1,
}));

export const StyledFormButtons = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const StyledFormButton = styled('div')(() => ({
  flex: 1,
}));
